import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../components/filter/CustomDatePicker';
import APPCONSTANTS from '../../constants/appConstants';
import DetailCard from '../../components/detailCard/DetailCard';
import CustomTable from '../../components/customtable/CustomTable';
import Loader from '../../components/loader/Loader';
import styles from './ReportList.module.scss';
import Modal from '../../components/modal/ModalForm';
import SpreadsheetViewer from '../../components/spreadSheetViewer/SpreadSheetViewer';
import { fetchReportList, fileDownload, fetchPatientStatusReport } from '../../services/reportAPI';
import { getPageLocationWithParams, trackGoogleAnalyticsEvent } from '../../utils/commonUtils';
import toastCenter from '../../utils/toastCenter';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import { firstNameSelector, lastNameSelector, roleSelector } from '../../store/user/selectors';

interface IMatchParams {
  siteId: string;
  siteName: string;
}

interface IDatesParams {
  id?: number;
  year?: string;
  month?: string;
  label?: string;
}

interface IReportLists {
  id: number;
  key: string;
}

const PatientStatusReport = (props: IMatchParams): React.ReactElement => {
  const { siteId, siteName } = useParams<IMatchParams>();
  const startYearPickerRef = useRef<DatePicker>(null);
  const startMonthPickerRef = useRef<DatePicker>(null);
  const [modalParams, setModalReqParams] = useState({
    loading: false,
    data: {}
  });
  const [loading, setLoading] = useState(false);
  const [reportParams, setReportParams] = useState<IDatesParams[]>([]);
  const [startYear, setStartYear] = useState<null | Date>(null);
  const [startMonth, setStartMonth] = useState<null | Date>(null);
  const role: any = useSelector(roleSelector);
  const firstName: string = useSelector(firstNameSelector) || '';
  const lastName: string = useSelector(lastNameSelector) || '';
  let selectedYear: string;
  let selectedMonth: string;

  useEffect(() => {
    getReportList('');
    trackGoogleAnalyticsEvent(
      APPCONSTANTS.USER_ACTIONS.LIST_VIEW,
      APPCONSTANTS.REPORT_LIST.PATIENT_STATUS_REPORT,
      getPageLocationWithParams(),
      { username: `${firstName} ${lastName}`, role: role.label }
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!startYear) {
      startMonthPickerRef.current?.props.onChange(null, undefined);
      setStartMonth(null);
    }
    if ((startYear && !startMonth) || (startYear && startMonth)) {
      startMonthPickerRef.current?.props.minDate?.setDate(startYear.getDate());
      startMonthPickerRef.current?.props.minDate?.setMonth(startYear.getMonth());
      startMonthPickerRef.current?.props.minDate?.setFullYear(startYear.getFullYear());
    }
    if (startMonth) {
      startYearPickerRef.current?.props.maxDate?.setDate(startMonth.getDate());
      startYearPickerRef.current?.props.maxDate?.setMonth(startMonth.getMonth());
      startYearPickerRef.current?.props.maxDate?.setFullYear(startMonth.getFullYear());
    }
  }, [startYear, startMonth]);

  const getReportList = useCallback(
    async (yearMonth: string) => {
      const datesList: IDatesParams[] = [];
      try {
        setLoading(true);
        const payLoad = await fetchReportList({
          key: yearMonth,
          reportType: 'PATIENT_STATUS_REPORT',
          healthFacilityId: Number(siteId),
          limit: APPCONSTANTS.NUMBER.TWELVE
        });
        payLoad.data.entityList.map((res: IReportLists) => {
          return datesList.push({
            id: res.id,
            year: res.key.slice(3, 7),
            month: formatMonthName(res.key.slice(0, 3)),
            label: res.key.slice(0, 3)
          });
        });
        setLoading(false);
        setReportParams(datesList);
      } catch (error: any) {
        setReportParams(datesList);
        setLoading(false);
        toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.REPORT_NOT_FOUND);
      }
    },
    [siteId]
  );

  const formatMonthName = (month: string) => {
    const result = APPCONSTANTS.MONTH.find((item) =>
      Object.entries(item).some(([key, value]) => key === 'label' && value === month)
    );
    return result?.name;
  };

  const getReportView = useCallback(
    async (report) => {
      try {
        setLoading(true);
        const {
          data: { entityList }
        } = await fetchPatientStatusReport({
          month: report.label,
          year: report.year,
          healthFacilityId: Number(siteId)
        });
        trackGoogleAnalyticsEvent(
          APPCONSTANTS.USER_ACTIONS.REPORT_VIEW,
          APPCONSTANTS.REPORT_LIST.PATIENT_STATUS_REPORT,
          getPageLocationWithParams(),
          { username: `${firstName} ${lastName}`, role: role.label }
        );
        setLoading(false);
        setModalReqParams({ loading: true, data: entityList });
      } catch (error: any) {
        setLoading(false);
        toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.REPORT_NOT_FOUND_MONTH);
      }
    },
    [firstName, lastName, role.label, siteId]
  );

  const downloadFile = useCallback(
    async (report) => {
      try {
        setLoading(true);
        const {
          data: { entityList }
        } = await fetchPatientStatusReport({
          month: report.label,
          year: report.year,
          healthFacilityId: Number(siteId)
        });
        trackGoogleAnalyticsEvent(
          APPCONSTANTS.USER_ACTIONS.REPORT_DOWNLOAD,
          APPCONSTANTS.REPORT_LIST.PATIENT_STATUS_REPORT,
          getPageLocationWithParams(),
          { username: `${firstName} ${lastName}`, role: role.label }
        );
        setLoading(false);
        fileDownload(siteName, '_Patient_Status_', report.year + '_' + report.month, entityList);
        toastCenter.success(APPCONSTANTS.SUCCESS, APPCONSTANTS.REPORT_DOWNLOAD_SUCCESS);
      } catch (error: any) {
        setLoading(false);
        toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.REPORT_NOT_FOUND_MONTH);
      }
    },
    [firstName, lastName, role.label, siteId, siteName]
  );

  const viewModalRender = () => {
    return (
      <SpreadsheetViewer
        data={modalParams.data}
        isEdit={false}
        className={styles.spreadSheetContainer}
        reportSize={{ charPixel: 7, index: [], rowHeight: 25 }}
      />
    );
  };

  const handleCancelClick = () => {
    setModalReqParams({ loading: false, data: {} });
  };

  const onYearSelection = (date: Date) => {
    if (date) {
      trackGoogleAnalyticsEvent(
        APPCONSTANTS.USER_ACTIONS.FILTERS.YEAR_FILTER,
        APPCONSTANTS.REPORT_LIST.PATIENT_STATUS_REPORT,
        getPageLocationWithParams(),
        { username: `${firstName} ${lastName}`, role: role.label }
      );
      setStartYear(date);
      selectedYear = date.getFullYear().toString();
      if (startMonth) {
        selectedMonth = APPCONSTANTS.MONTH[startMonth.getMonth()].label;
        getReportList(selectedMonth + selectedYear);
      } else {
        getReportList(selectedYear);
      }
    } else {
      setStartYear(null);
      setStartMonth(null);
      getReportList('');
    }
  };

  const onMonthSelection = (date: Date) => {
    if (date) {
      trackGoogleAnalyticsEvent(
        APPCONSTANTS.USER_ACTIONS.FILTERS.MONTH_FILTER,
        APPCONSTANTS.REPORT_LIST.PATIENT_STATUS_REPORT,
        getPageLocationWithParams(),
        { username: `${firstName} ${lastName}`, role: role.label }
      );
      setStartMonth(date);
      selectedMonth = APPCONSTANTS.MONTH[date.getMonth()].label;
      if (startYear) {
        selectedYear = startYear.getFullYear().toString();
        getReportList(selectedMonth + selectedYear);
      }
    } else {
      setStartMonth(null);
      if (startYear) {
        selectedYear = startYear.getFullYear().toString();
        getReportList(selectedYear);
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className='row'>
        <div className='col-auto'>
          <div className={`${styles.sideMenu} ${styles.customSidemenu}`}>
            <div className={`card-header bg-transparent ${styles.header}`}>
              <span className={styles.headerLabel}>Filters</span>
            </div>
            <br />
            <div>
              <label>Year</label>
              <CustomDatePicker
                pickerRef={startYearPickerRef}
                dayReducer={1}
                onDateSelect={(date: Date) => onYearSelection(date)}
                showYearPicker={true}
              />
              <br />
              <br />
              <div className={startYear ? '' : `${styles.disable}`}>
                <label>Month</label>
                <CustomDatePicker
                  pickerRef={startMonthPickerRef}
                  onDateSelect={(date: Date) => onMonthSelection(date)}
                  showMonthYearPicker={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col'>
          <DetailCard header='Patient Status Report'>
            <CustomTable
              rowData={reportParams}
              columnsDef={[
                {
                  id: 1,
                  name: 'year',
                  label: 'Year',
                  class: 'numeric'
                },
                {
                  id: 2,
                  name: 'month',
                  label: 'Month'
                }
              ]}
              isDelete={false}
              isEdit={false}
              isView={true}
              isDownload={true}
              onRowView={(report) => getReportView(report)}
              onRowDownload={(report) => downloadFile(report)}
            />
          </DetailCard>
          <Modal
            show={modalParams.loading}
            title='Patient Status Report View'
            size='modal-full'
            hasFooter={false}
            handleCancel={handleCancelClick}
            handleFormSubmit={handleCancelClick}
            render={viewModalRender}
          />
        </div>
      </div>
    </>
  );
};

export default PatientStatusReport;
