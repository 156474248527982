import React, { useEffect, useState, useRef } from 'react';
import styles from './UserPerformance.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import ERRORS from '../../constants/error';
import APPCONSTANTS from '../../constants/appConstants';
import DetailCard from '../../components/detailCard/DetailCard';
import CustomTable from '../../components/customtable/CustomTable';
import { loadingSelector, totalSelector, userPerformanceListSelector } from '../../store/report/selectors';
import { loadingSelector as userLoadingSelector } from '../../store/user/selectors';
import { fetchUserPerformanceListRequest } from '../../store/report/actions';
import toastCenter from '../../utils/toastCenter';
import Loader from '../../components/loader/Loader';
import FilterLayout from '../../components/filter/FilterLayout';
import { downloadUserPerformanceReport } from '../../services/reportAPI';
import { getPageLocationWithParams, trackGoogleAnalyticsEvent } from '../../utils/commonUtils';
import { firstNameSelector, lastNameSelector, roleSelector } from '../../store/user/selectors';
import sessionStorageService from '../../global/sessionStorageServices';
import { getRegionDetailSelector } from '../../store/region/selectors';
import { fetchRolesListRequest } from '../../store/user/actions';
import { IRoles } from '../../store/user/types';

interface IMatchParams {
  siteId: string;
  siteName: string;
  regionId: string;
}

const UserPerformance = (props: IMatchParams): React.ReactElement => {
  const fromDatePickerRef = useRef<DatePicker>(null);
  const toDatePickerRef = useRef<DatePicker>(null);
  const [listParams, setListReqParams] = useState({
    page: APPCONSTANTS.NUMBER.ONE,
    rowsPerPage: APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE,
    searchTerm: ''
  });

  const [fromDate, setFromDate] = useState<Date | null | undefined>(undefined);
  const [toDate, setToDate] = useState<Date | null | undefined>(undefined);
  const [roles, setRoles] = useState<Array<string | null>>([]);
  const [rolesList, setRolesList] = useState<Array<IRoles>>([]);
  const [exportLoading, setExportLoading] = useState(false);
  const dispatch = useDispatch();
  const userPerformanceList = useSelector(userPerformanceListSelector);
  const loading = useSelector(loadingSelector);
  const roleListLoading = useSelector(userLoadingSelector);
  const regionDetails = useSelector(getRegionDetailSelector);
  const userPerformanceCount = useSelector(totalSelector);
  const { siteId, siteName, regionId } = useParams<IMatchParams>();
  const prevDateFetchValue = useRef<any>({
    fromDate: null,
    toDate: null
  });
  const role: any = useSelector(roleSelector);
  const firstName: string = useSelector(firstNameSelector) || '';
  const lastName: string = useSelector(lastNameSelector) || '';

  const countryId = Number(regionId || sessionStorageService.getItem(APPCONSTANTS.REGION_ID) || regionDetails.id);

  useEffect(() => {
    if (!fromDate) {
      toDatePickerRef.current?.props.onChange(null, undefined);
      setToDate(null);
    }
    if ((fromDate && !toDate) || (fromDate && toDate)) {
      toDatePickerRef.current?.props.minDate?.setDate(fromDate.getDate());
      toDatePickerRef.current?.props.minDate?.setMonth(fromDate.getMonth());
      toDatePickerRef.current?.props.minDate?.setFullYear(fromDate.getFullYear());
    }
    if (toDate) {
      trackGoogleAnalyticsEvent(
        APPCONSTANTS.USER_ACTIONS.FILTERS.DATE_FILTER,
        APPCONSTANTS.REPORT_LIST.USER_PERFORMANCE_REPORT,
        getPageLocationWithParams(),
        { username: `${firstName} ${lastName}`, role: role.label }
      );
      fromDatePickerRef.current?.props.maxDate?.setDate(toDate.getDate());
      fromDatePickerRef.current?.props.maxDate?.setMonth(toDate.getMonth());
      fromDatePickerRef.current?.props.maxDate?.setFullYear(toDate.getFullYear());
    }
  }, [toDatePickerRef, fromDate, toDate, loading, exportLoading, firstName, lastName, role.label]);

  useEffect(() => {
    if (roles.length || (fromDate && toDate)) {
      setListReqParams((prevstate) => {
        return {
          ...prevstate,
          page: 1
        };
      });
    }
  }, [fromDate, toDate, roles]);

  const reqPayload = (newParams: any) => {
    const reqData = {
      healthFacilityId: Number(siteId),
      skip: (newParams.page - 1) * listParams.rowsPerPage,
      limit: newParams.rowsPerPage,
      failureCb: (e: Error) => {
        if (e.message === ERRORS.NETWORK_ERROR.message) {
          toastCenter.error(APPCONSTANTS.NETWORK_ERROR, APPCONSTANTS.CONNECTION_LOST);
        } else {
          toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.USER_PERFORMANCE_FETCH_ERROR);
        }
      }
    };
    if (fromDate && toDate) {
      Object.assign(reqData, {
        fromDate: `${moment(fromDate).format(APPCONSTANTS.YEAR_MONTH_DATE_FORMAT)}T00:00:00.000Z`,
        toDate: `${moment(toDate).format(APPCONSTANTS.YEAR_MONTH_DATE_FORMAT)}T00:00:00.000Z`
      });
    }
    if (roles.length) {
      Object.assign(reqData, { roles });
    }
    return reqData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const fetchList = (newParams: any) => {
    dispatch(fetchUserPerformanceListRequest({ payload: reqPayload(newParams) }));
    setListReqParams(newParams);
  };

  useEffect(() => {
    trackGoogleAnalyticsEvent(
      APPCONSTANTS.USER_ACTIONS.REPORT_VIEW,
      APPCONSTANTS.REPORT_LIST.USER_PERFORMANCE_REPORT,
      getPageLocationWithParams(),
      { username: `${firstName} ${lastName}`, role: role.label }
    );
    const newParams = {
      ...listParams,
      page: 1
    };
    fetchList(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, roles, dispatch]);

  useEffect(() => {
    const formatDate = (date: any) => (date ? moment(date).format(APPCONSTANTS.YEAR_MONTH_DATE_FORMAT) : null);
    const prevFromDate = formatDate(prevDateFetchValue.current?.fromDate);
    const prevToDate = formatDate(prevDateFetchValue.current?.toDate);
    const currFromDate = formatDate(fromDate);
    const currToDate = formatDate(toDate);
    const valueChanged = () => {
      return prevFromDate !== currFromDate || prevToDate !== currToDate;
    };
    if ((fromDate && toDate && valueChanged()) || (fromDate === null && toDate === null && valueChanged())) {
      prevDateFetchValue.current = {
        fromDate: currFromDate,
        toDate: currToDate
      };
      const newParams = {
        ...listParams,
        page: 1
      };
      console.log('newParams-->', newParams);
      fetchList(newParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, dispatch]);

  // fetch role-list for role filter
  useEffect(() => {
    dispatch(
      fetchRolesListRequest({
        countryId,
        successCB: (roles: IRoles[]) => {
          setRolesList(roles || []);
        },
        failureCB: (e) => {
          console.error(e.message);
          setRoles([]);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  const handlePage = (pageNo: number, rowsPerPage: number | undefined = APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE) => {
    const newParams = {
      ...listParams,
      page: pageNo,
      rowsPerPage
    };
    fetchList(newParams);
  };

  const handleRoleOnChange = (event: React.BaseSyntheticEvent) => {
    if (event.target.checked) {
      trackGoogleAnalyticsEvent(
        APPCONSTANTS.USER_ACTIONS.FILTERS.ROLE_FILTER,
        APPCONSTANTS.REPORT_LIST.USER_PERFORMANCE_REPORT,
        getPageLocationWithParams(),
        { username: `${firstName} ${lastName}`, role: role.label }
      );
      setRoles((values) => [...values, event.target.value]);
    } else {
      const index = roles?.indexOf(event.target.value);
      const updatedValue = roles;
      updatedValue.splice(index, 1);
      setRoles((values) => [...updatedValue]);
    }
  };

  const exportUserPerformanceReport = async () => {
    try {
      setExportLoading(true);
      const payLoad = {
        healthFacilityId: siteId,
        siteName,
        limit: userPerformanceCount
      };
      if (fromDate && toDate) {
        Object.assign(payLoad, {
          fromDate: moment(fromDate).format(APPCONSTANTS.YEAR_MONTH_DATE_FORMAT),
          toDate: moment(toDate).format(APPCONSTANTS.YEAR_MONTH_DATE_FORMAT)
        });
      }
      if (roles.length) {
        Object.assign(payLoad, { roles });
      }
      await downloadUserPerformanceReport(payLoad);
      trackGoogleAnalyticsEvent(
        APPCONSTANTS.USER_ACTIONS.REPORT_DOWNLOAD,
        APPCONSTANTS.REPORT_LIST.USER_PERFORMANCE_REPORT,
        getPageLocationWithParams(),
        { username: `${firstName} ${lastName}`, role: role.label }
      );
      setExportLoading(false);
      toastCenter.success(APPCONSTANTS.SUCCESS, APPCONSTANTS.REPORT_DOWNLOAD_SUCCESS);
    } catch {
      setExportLoading(false);
      toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.DOWNLOAD_FAIL_MSG);
    }
  };
  return (
    <>
      {(loading || exportLoading || roleListLoading) && <Loader />}
      <div className='row'>
        <FilterLayout
          fromDateSelector={(date: Date) => {
            setFromDate(date);
          }}
          toDateSelector={(date: Date) => setToDate(date)}
          handleRoleOnChange={(event: React.BaseSyntheticEvent) => handleRoleOnChange(event)}
          fromDate={fromDate}
          fromDatePickerRef={fromDatePickerRef}
          toDatePickerRef={toDatePickerRef}
          showCommonFilters={false}
          showUserPerformanceFilters={true}
          userPerformanceRoles={rolesList}
        />
        <div className={`${styles.overrideWidth} 'col'`}>
          <DetailCard
            header='User Performance'
            isSearch={false}
            buttonLabel='Export'
            onButtonClick={() => exportUserPerformanceReport()}
            isDownload={true}
            isDisabled={userPerformanceList.length === 0 ? true : false}
          >
            <CustomTable
              rowData={userPerformanceList}
              columnsDef={[
                {
                  id: 1,
                  name: 'firstName',
                  label: 'Name',
                  width: '140px'
                },
                {
                  id: 2,
                  name: 'userName',
                  label: 'Email',
                  width: '340px'
                },
                {
                  id: 3,
                  name: 'userRole',
                  label: 'Role',
                  width: '200px'
                },
                {
                  id: 4,
                  name: 'screeningCount',
                  label: 'Screening',
                  class: 'numeric',
                  width: '130px'
                },
                {
                  id: 5,
                  name: 'enrollmentCount',
                  label: 'Enrollment',
                  class: 'numeric',
                  width: '130px'
                },
                {
                  id: 6,
                  name: 'bpAssessmentCount',
                  label: 'BP Assessed',
                  class: 'numeric',
                  width: '130px'
                },
                {
                  id: 7,
                  name: 'bgAssessmentCount',
                  label: 'BG Assessed',
                  class: 'numeric',
                  width: '130px'
                },
                {
                  id: 8,
                  name: 'medicalReviewCount',
                  label: 'Medical Review',
                  class: 'numeric',
                  width: '130px'
                },
                {
                  id: 9,
                  name: 'nutritionLifestyleCount',
                  label: 'Nutrition Lifestyle',
                  class: 'numeric',
                  width: '130px'
                },
                {
                  id: 10,
                  name: 'labTestCount',
                  label: 'Lab Test',
                  class: 'numeric',
                  width: '130px'
                },
                {
                  id: 11,
                  name: 'prescriptionCount',
                  label: 'Prescription',
                  class: 'numeric',
                  width: '130px'
                }
              ]}
              isDelete={false}
              isEdit={false}
              isView={false}
              isDownload={false}
              page={listParams.page}
              rowsPerPage={listParams.rowsPerPage}
              count={userPerformanceCount}
              handlePageChange={handlePage}
            />
          </DetailCard>
        </div>
      </div>
    </>
  );
};

export default UserPerformance;
